import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import LocationContext from '../lib/locationContext';
import { BackArrow, NavBar } from './Responses';
import { GlitchText, GlitchLink } from '../components/GlitchText';
import { FadeIn } from '../components/FullPageWrapper';
import { PAGES, logEvent } from '../lib/firebase';

const About = () => {
  const { setCurrentLocation } = useContext(LocationContext);
  useEffect(() => {
    setCurrentLocation(window.location.pathname);
    logEvent({ page: PAGES.ABOUT });
  }, []);
  return (
    <AboutContainer>
      {/* <ImageBackground src={blurBg} /> */}
      <NavBar>
        <BackArrow />
        <GlitchText text="About">About</GlitchText>
        <div style={{ width: '32px', height: '32px' }} />
      </NavBar>
      <AboutContent>
        <p>
          Before We Disappear is an interactive piece that comes from our
          embodied experience, of being watched, seen, observed and not being
          able to control that gaze. It came from thinking about how to subvert
          that. Created by Asmaa Jama in collaboration with Gouled Ahmed,
          Gebriel Balcha, Joseph Horton, Ibrahim Hirsi and Roseanna Dias.
        </p>
        <p>
          Making this piece was tiring, but encountering some of the sounds of
          this piece from artists like Aweys Khamiis Cabdalla, sourced by
          Ibrahim was a necessary sort of care and protection.
        </p>
        <p>
          This piece wants you to interact with it, it hopes to archive your
          stories. We understand archiving has violent connotations sometimes,
          and understand if you do not want to be seen. Both are possible here.
        </p>
        <p>
          If you need more support after being a part of this experience, visit{' '}
          <GlitchLink decoration="underline" text="visit here" to="/resources">
            here
          </GlitchLink>
          .
        </p>
        <p>
          I would like to acknowledge the artists that inspired this work, this
          is after and in response to Safia Elhillo, Lily Green, Sarah Gulami,
          Baloji, Yinka Esi Graves, Danielle Brathwaite-Shirley and Yumna
          Al-Arashi.
        </p>
        <img
          style={{ marginBottom: '2rem', maxWidth: '800px', width: '100%' }}
          src="images/bts.jpg"
          alt="Behind the Scenes shooting Before We Disappear"
        />
        <p>
          Want to be involved in future iterations of this work?{' '}
          <a href="mailto:beforewedisappear.project@gmail.com">
            Let us know here
          </a>
          .
        </p>
      </AboutContent>
    </AboutContainer>
  );
};

export default About;

export const AboutContainer = styled.div`
  padding: 0 min(4rem, 4vw);
  display: grid;
  min-height: 100%;
  animation-name: ${FadeIn};
  animation-duration: 2s;
  opacity: 0;
  animation-fill-mode: forwards;
`;

export const AboutContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  p {
    margin-bottom: 2rem;
  }

  a {
    color: ${(props) => props.theme.colours.light};
    :hover {
      font-weight: 700;
    }
  }
`;

export const Circle = styled.div`
  background-color: ${(props) => props.theme.colours.light};
  width: ${(props) => (props.size ? props.size : '24px')};
  height: ${(props) => (props.size ? props.size : '24px')};
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1rem;
`;
